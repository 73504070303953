import { render, staticRenderFns } from "./vip-test-exam.vue?vue&type=template&id=01f08bbc&scoped=true&"
import script from "./vip-test-exam.vue?vue&type=script&lang=js&"
export * from "./vip-test-exam.vue?vue&type=script&lang=js&"
import style0 from "./vip-test-exam.vue?vue&type=style&index=0&id=01f08bbc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01f08bbc",
  null
  
)

export default component.exports